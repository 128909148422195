import React from "react";
import "./App.css";
import YamlGenerator from "./YamlGenerator";

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <YamlGenerator />
        </header>
      </div>
  );
}

export default App;
