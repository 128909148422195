import React, { useState } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Tooltip } from 'primereact/tooltip';
import 'primeicons/primeicons.css';

const YamlGenerator = () => {
  const [formData, setFormData] = useState({
    appName: "",
    namespace: "carfile",
    repoURL: "",
    targetRevision: "main",
    imageURL: "",
    imagePort: "",
    token: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const base64Encode = (str) => {
    return btoa(str);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const zip = new JSZip();

    const dockerConfigJson = {
      auths: {
        [formData.imageURL]: {
          username: "iliyahristov",
          password: formData.token
        }
      }
    };
    const dockerConfigJsonString = JSON.stringify(dockerConfigJson);
    const encodedDockerConfigJson = base64Encode(dockerConfigJsonString);
    const path = formData.targetRevision === 'main' ? 'main' : "dev";
    // Application YAML
    const applicationYaml = `
apiVersion: argoproj.io/v1alpha1
kind: Application
metadata:
  name: ${formData.appName}
spec:
  project: ${formData.namespace}
  source:
    repoURL: ${formData.repoURL}
    targetRevision: ${formData.targetRevision}
    path: ${path}
  destination: 
    server: https://kubernetes.default.svc
    namespace: ${formData.namespace}

  syncPolicy:
    syncOptions:
    - CreateNamespace=true

    automated:
      selfHeal: true
      prune: true
    `.trim();

    // Secret YAML
    const secretYaml = `
apiVersion: v1
kind: Secret
metadata:
  name: ${formData.appName}-secret
  namespace: ${formData.namespace}
type: kubernetes.io/dockerconfigjson
data:
  .dockerconfigjson: ${encodedDockerConfigJson}
    `.trim();

    // Deployment YAML
    const deploymentYaml = `
apiVersion: apps/v1
kind: Deployment
metadata:
  name: ${formData.appName}
  namespace: ${formData.namespace}
spec:
  replicas: 3
  selector:
    matchLabels:
      app: ${formData.appName}
  template:
    metadata:
      labels:
        app: ${formData.appName}
    spec:
      containers:
      - name: ${formData.appName}
        image: ${formData.imageURL}:latest
        ports:
        - containerPort: ${formData.imagePort}
      imagePullSecrets:
        - name: ${formData.appName}-secret
    `.trim();

    // Service YAML
    const serviceYaml = `
apiVersion: v1
kind: Service
metadata:
  name: ${formData.appName}-svc
  namespace: ${formData.namespace}
spec:
  selector:
    app: ${formData.appName}
  ports:
  - protocol: TCP
    port: ${formData.imagePort}
    targetPort: ${formData.imagePort}
  externalIPs:
    - 10.20.20.201
    - 10.20.20.202
    - 10.20.20.203
    `.trim();

    zip.file("application.yaml", applicationYaml);
    zip.file(path + "/secret.yaml", secretYaml);
    zip.file(path + "/deployment.yaml", deploymentYaml);
    zip.file(path + "/service.yaml", serviceYaml);

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `${formData.appName}-yamls.zip`);
  };

  return (
    <div>
      <h2>Kubernetes YAML Generator</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>App name <i className="pi pi-info-circle" id="appName"></i>:</label>
          <input type="text" name="appName" value={formData.appName} onChange={handleChange} required/>
          <Tooltip target="#appName" content="Enter the name of the microsercice"
                   style={{backgroundColor: 'black', color: "white"}}/>
        </div>
        {/*<div>*/}
        {/*  <label>Namespace:</label>*/}
        {/*  <input type="text" name="namespace" value={formData.namespace} onChange={handleChange} required />*/}
        {/*</div>*/}
        <div>
          <label>ArgoCD Application repo URL <i className="pi pi-info-circle" id="repoURL"></i>:</label>
          <input type="text" name="repoURL" value={formData.repoURL} onChange={handleChange} required/>
          <Tooltip target="#repoURL" content="Enter the AegoCD application repo here"
                   style={{backgroundColor: 'black', color: "white"}}/>
        </div>
        <div>
          <label>Target Branch:</label>
          <select name="targetRevision" value={formData.targetRevision} onChange={handleChange} required>
            <option value="main">main</option>
            <option value="develop">develop</option>
          </select>
        </div>
        <div>
          <label>Microservice image URL:</label>
          <input type="text" name="imageURL" value={formData.imageURL} onChange={handleChange} required/>
        </div>
        <div>
          <label>Microservice image token:</label>
          <input type="text" name="token" value={formData.token} onChange={handleChange}/>
        </div>
        <div>
          <label>Microservice Port:</label>
          <input type="text" name="imagePort" value={formData.imagePort} onChange={handleChange} required/>
        </div>
        <button type="submit">Generate</button>
      </form>
    </div>
  );
};

export default YamlGenerator;

